<template>
    <div>
        <b-button @click="activeModal = true" :disabled="!canClosed" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
            <feather-icon icon="XIcon" /> Cerrar Factura x
        </b-button>

        <b-modal ref="modalCloseInvoice" id="modalCloseInvoice" :title="`Factura ${routing.folio}`" no-close-on-backdrop hide-footer
            @cancel="activeModal = false" @hidden="closeModal" :visible="activeModal">

            <b-row>
                <b-col cols="12">
                    <div class="demo-spacing-0">
                        <b-alert show variant="danger">
                            <div class="alert-body">
                                <feather-icon class="mr-25" icon="AlertTriangleIcon"/>
                                <span v-if="!isPending" class="ml-25">¿Está seguro de que desea cerrar la factura?, Esta acción no se puede revertir.</span>
                                <span v-else class="ml-25">Existen artículos con pendientes de entrega, Elige una opción para continuar.</span>
                            </div>
                        </b-alert>
                    </div>        
                </b-col>

                <b-col cols="12">
                    
                </b-col>
            </b-row>
            

            <b-row class="mt-2">
                <b-col cols="12">
                    <div v-if="!isPending" class="text-right">
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                            variant="outline-secondary" @click="closeModal" class="mr-2">
                            Cancelar
                        </b-button>

                        <b-button @click="activeModal = false" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="button">
                            Cerrar Factura
                        </b-button>
                    </div>
                    <div v-else class="text-center">
                        <b-button v-if="isPending" @click="activeModal = false" class="mr-1"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="button">
                            Cerrar Con Pendientes
                        </b-button>

                        <b-button v-if="isPending" @click="activeModal = false"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" type="button">
                            Cerrar y Generar Salidas
                        </b-button>

                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                            class="mt-2" variant="outline-secondary" @click="closeModal">
                            Cancelar
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>        

<script>
import { debounce } from 'lodash';
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, 
    BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar,
    BBadge, BListGroup, BListGroupItem, BTab, BTabs,
} from 'bootstrap-vue'
import store from '@/store'
import router from "@/router";
import vSelect from "vue-select";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from 'vee-validate/dist/rules';
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import EventBus from "@/event-bus"
import FeatherIcon from '../feather-icon/FeatherIcon.vue';



export default {
    name: 'CoseInvoice',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BTab, 
        BTabs,
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardSubTitle,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BBadge,
        BListGroup, BListGroupItem,
        FeatherIcon,

        //
        VuePerfectScrollbar,
        vSelect,
        
    },
    mixins: [heightTransition],
    props: {
        routing: {
            type: Object,
            default: () => {},
            required: false,
        },
    },
    data () {
        return {
            
        }    
    },
    computed: {
        canClosed() {
            return !this.loading && this.routing.estatus !== 'CERRADA' && this.routing.estatus == 'EN RUTA';
        },
        isPending() {
            return this.routing.articles.reduce((carry, item) => {
                return carry + item.pendiente;                 
            }, 0) > 0
        },
    },
    created() {        
        
    },
    beforeDestroy() {
        
    },
    async mounted() {
        
    },
    beforeDestroy() {
        
    },
    methods: {
        
    },
    setup(props, { emit }) {
        const loading = ref(false)
        const activeModal = ref(false)

        const closeModal = () => {
            activeModal.value = false
        }

        return {
            loading,
            activeModal,

            //Methods
            closeModal,
        }
    }

}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.scrollable-container {
  max-height: 400px; /* Ajusta según tus necesidades */
  overflow: auto;
}
</style>
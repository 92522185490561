<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-table :fields="columnTransfers" class="position-relative b-table-responsive mb-0 text-small small"
        :items="transfers" show-empty empty-text="No se encontraron resultados" :busy.sync="loading" 
        small
        :sticky-header="isSticky"
        :no-border-collapse="true">
            <template slot="empty">
                <h6 class="text-center mt-1">
                    No se encontraron registros
                </h6>
            </template>

            <template #cell(tiempo_Transcurrido)="data">
                <span class="text-capitalize">{{ mostrarTiempoTranscurrido(data.item.fecha_Documento, data.item.fecha_Alta) }}</span>
                
            </template>

            <template #cell(fecha_Alta)="data">
                <span class="text-capitalize">{{ formatDate(data.item.fecha_Alta) }}</span>
            </template>
        </b-table>
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import EventBus from "@/event-bus"
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import { VueGoodTable } from 'vue-good-table'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex';
import ArticlesInvoice from '@/@core/components/invoices/ArticlesInvoice.vue';
import AuthorizationModal from '@/@core/components/AuthorizationModal.vue'
import SearchByCustomer from "@core/components/invoices/SearchByCustomer.vue"

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

import DoctosPVProvider from "@/providers/DoctosPV"
import FeatherIcon from '../feather-icon/FeatherIcon.vue';
const DoctosPVResource = new DoctosPVProvider()

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
import moment from 'moment';
import { nextTick } from '@vue/composition-api';
const DeliveryRoutesResource = new DeliveryRoutesProvider();

export default {
    name: 'SearchInvoices',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        FeatherIcon,
        BTabs,
        BTab,

        //
        ArticlesInvoice,
        AuthorizationModal,
        SearchByCustomer,
        vSelect,
    },
    mixins: [heightTransition],
    props: {
       transfers: {
            type: Array,
            default: () => [],
            required: true,
        },
        isSticky: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            columnTransfers: [
                {
                    key: 'usuario_Nombre',
                    label: 'Generado Por',
                },
                {
                    key: 'fecha_Alta',
                    label: 'Fecha Hora',                    
                },
                {
                    key: 'tiempo_Transcurrido',
                    label: 'Tiempo Transcurrido'
                },
                {
                    key: 'tipo_Docto',
                    label: 'Tipo Documento'
                },
                {
                    key: 'accion',
                    label: 'Acción',
                },
                {
                    key: 'chofer_Nombre',
                    label: 'Recibio',
                },
                {
                    key: 'camion_Nombre',
                    label: 'Camión'
                },                
               
            ]
        }
    },
    created () {},
    computed: {},
    mounted() {},
    beforeDestroy() {},
    methods: {},
    watch: {}    
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>